import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};
httpOptions.headers.append(
  "Access-Control-Allow-Methods",
  "GET, POST, OPTIONS, PUT, DELETE"
);
@Injectable({
  providedIn: "root"
})
export class BaseApiService {
  protected actionApiUrl: string;
  constructor(public http: HttpClient, public apiUrl: string) {
    this.actionApiUrl = apiUrl;
  }

  public GetAll<T>(): Observable<any> {
    return this.http.get<T>(this.actionApiUrl, httpOptions);
  }

  public GetSingle<T>(id: number | string): Observable<T> {
    return this.http.get<T>(this.actionApiUrl + "/" + id, httpOptions);
  }

  public Create<T>(itemToAPost: any): Observable<T> {
    const data: any = <T>itemToAPost;
    const body = JSON.stringify(data);

    return this.http.post<T>(this.actionApiUrl, body, httpOptions);
  }

  public Update<T>(id: number | string, itemToUpdate: any): Observable<T> {
    return this.http.put<T>(
      this.actionApiUrl + "/" + id,
      itemToUpdate,
      httpOptions
    );
  }

  public Delete<T>(id: number | string): Observable<T> {
    return this.http.delete<T>(this.actionApiUrl + "/" + id, httpOptions);
  }
}

export function isGuid(value: string): boolean {
  return /^\{?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\}?$/.test(
    value
  );
}

export function hasValue(value: any): boolean {
  return value !== null && value !== undefined;
}
