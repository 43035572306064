import { environment } from "./../../../environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { NotificationsService } from "../notifications/notifications.service";
import { NaxosError } from "./models/naxos-error";
import { NaxosProblemDetails } from "./models/naxos-problem-details";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor(
    private notifications: NotificationsService,
    ) {}

  async error(error: Error) {
    let naxosError = new NaxosError();
    if (error instanceof HttpErrorResponse) {
      if (environment.dev || environment.local) {
        let httpError = new NaxosError();
        if (error.status === 0) {
          httpError.message =
            "API is not available. Please check your connection.";
          httpError.title = "Error";
        } else {
          httpError.message = error.message;
          httpError.title = error.statusText;
        }
        httpError.error = error;
        this.notifications.error(httpError);
      }
      if (error?.error) {
        let newError = error.error;
        if (error?.error instanceof Blob) {
          newError = await this.parseErrorBlob(error);
        }
        if (this.isNaxosProblemDetails(newError)) {
          naxosError.message = newError.detail;
          naxosError.title = newError.title;
          naxosError.error = newError;
        }
      }
    } else {
      naxosError.message = error.message;
      naxosError.title = error.name;
      naxosError.error = error;
    }
    if (naxosError.message) {
      this.notifications.error(naxosError);
    }
  }

  isNaxosProblemDetails(npd): npd is NaxosProblemDetails {
    return (
      (<NaxosProblemDetails>npd).webApi !== undefined &&
      (<NaxosProblemDetails>npd).webApi !== null
    );
  }

  async parseErrorBlob(err: HttpErrorResponse) {
    const reader = new FileReader();

    let blobRead = new Subject();
    let blobReadPromise = blobRead.toPromise();

    let jsonResult = null;
    // This fires after the blob has been read/loaded.
    reader.onload = function () {
      blobRead.next(JSON.parse(<string>reader.result));
      blobRead.complete();
    };

    // Start reading the blob as text.
    reader.readAsText(err.error);

    let objectResult = await blobReadPromise;

    return objectResult;
  }
}
