import { Configuration, InteractionType } from "@azure/msal-browser";

export const environment = {
  dev: false, // DEV or LOCAL Environment
  test: false,
  qa: false,
  prod: false,
  local: true
};

export const apiSettings = {
  ReferentialApiEndPoint: "https://referential-api.naxosit.be.dev.naxosit.com",
  // ReferentialApiEndPoint: "http://localhost:7002",
  ProfileApiEndPoint: "https://profile-api.naxosit.be.dev.naxosit.com",
  // ProfileApiEndPoint: "http://localhost:7003",
  MissionApiEndPoint: "https://mission-api.naxosit.be.dev.naxosit.com",
  // MissionApiEndPoint: "http://localhost:7005",
  IdentityApiEndPoint: "https://identity-api.naxosit.be.dev.naxosit.com",
  // IdentityApiEndPoint: "http://localhost:32769",
  ContentApiEndPoint: "https://content-api.octopus.dev.naxosit.com/api/v1-read/",
  webBaseUrl: "https://www.naxosit.be.dev.naxosit.com",
  spaBaseUrl: "http://localhost:4200",
  ContentConsentMailingUrl: ""
};

export const popup = {
  disableClose: true,
  width: "calc(100% - 50px)",
  height: "calc(100% - 120px)",
};

// this checks if the app is running on IE
export const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

/** =================== REGIONS ====================
 * 1) B2C policies and user flows
 * 2) Web API configuration parameters
 * 3) Authentication configuration parameters
 * 4) MSAL-Angular specific configuration parameters
 * =================================================
 */

// #region 1) B2C policies and user flows
/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signinsignup: "B2C_1A_NAXOS_WEB_SIGNUP_SIGNIN",
  },
  authorities: {
    signinsignup: {
      authority:
        "https://naxositwebb2ctest.b2clogin.com/naxositwebb2ctest.onmicrosoft.com/B2C_1A_NAXOS_WEB_SIGNUP_SIGNIN",
    },
  },
};
// #endregion

// #region 2) Web API Configuration
/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
 export const apiIdentityConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: [,
    "https://b2c.naxosit.be.tst.naxosit.com/naxos-api/identity",
  ],
  webApi: apiSettings.IdentityApiEndPoint,
};
export const apiMissionConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: [
    "https://b2c.naxosit.be.tst.naxosit.com/naxos-api/mission",
    "https://b2c.naxosit.be.tst.naxosit.com/naxos-api/identity",
  ],
  webApi: apiSettings.MissionApiEndPoint,
};
export const apiProfileConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: [
    "https://b2c.naxosit.be.tst.naxosit.com/naxos-api/profile",
    "https://b2c.naxosit.be.tst.naxosit.com/naxos-api/identity",
  ],
  webApi: apiSettings.ProfileApiEndPoint,
};
export const apiReferentialConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: [
    "https://b2c.naxosit.be.tst.naxosit.com/naxos-api/referential",
    "https://b2c.naxosit.be.tst.naxosit.com/naxos-api/identity",
  ],
  webApi: apiSettings.ReferentialApiEndPoint,
};
// #endregion

// #region 3) Authentication Configuration
/**
 * Config object to be passed to Msal on creation. For a full list of msal.js configuration parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: "221bd9e3-a4d2-4a3f-af1a-903300d281c7",
    authority: b2cPolicies.authorities.signinsignup.authority,
    knownAuthorities: ["https://naxositwebb2ctest.b2clogin.com"],
    redirectUri: "http://localhost:4200/public/auth",
    postLogoutRedirectUri: "http://localhost:5050",
    navigateToLoginRequestUrl: false,
  },
  // cache: {
  //     cacheLocation: "localStorage",
  //     storeAuthStateInCookie: isIE, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE
  // },
};

// #region 4) MSAL-Angular Configuration
// here you can define the coordinates and required permissions for your protected resources
export const protectedResourceMapConfig: Map<string, Array<string>> = new Map<
  string,
  Array<string>
>([
  [apiIdentityConfig.webApi, apiIdentityConfig.b2cScopes], // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
  // [apiMissionConfig.webApi, apiMissionConfig.b2cScopes], // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
  // [apiProfileConfig.webApi, apiProfileConfig.b2cScopes], // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
  [apiReferentialConfig.webApi, apiReferentialConfig.b2cScopes], // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
]);

export const interactionType = InteractionType.Redirect;
