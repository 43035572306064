import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthGuard, AdminGuard } from "./security";
import { LogoutComponent } from "./components/logout/logout.component";
import { MsalGuard } from "@azure/msal-angular";
import { SignupComponent } from "./components/signup/signup.component";
import { LoginComponent } from "./components/login/login.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full"
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "logout",
    component: LogoutComponent,
    canActivate: [MsalGuard]
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      }
    ]
  },
  // REFERENTIAL SPA MODULE
  {
    path: "referential",
    component: AdminLayoutComponent,
    loadChildren: () => import('./layouts/referential/referential.module').then(m => m.ReferentialModule),
    canActivate: [AdminGuard]
  },
  // Profile SPA MODULE

  {
    path: "profile",
    component: AdminLayoutComponent,
    loadChildren: () => import('./layouts/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },

  // Profile SPA Mission

  {
    path: "mission",
    component: AdminLayoutComponent,
    loadChildren: () => import('./layouts/mission/mission.module').then(m => m.MissionModule),
    canActivate: [AuthGuard]
  },

  //public
  {
    path: "public",
    component: AdminLayoutComponent,
    loadChildren: () => import('./layouts/public/public.module').then(m => m.PublicModule)
  },

  { path: "**", redirectTo: "dasboard" }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: []
})
export class AppRoutingModule {}
