import { Component, OnInit } from "@angular/core";
import { FooterService } from "../service/footer.service";
import { Footer } from "../model/footer";
import { Menu } from "../model/menu";
import { apiSettings } from "environments/environment";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  public footer: Footer;
  public isFormReady = false;
  footerLinks = [];
  socialLinks = [];
  public modeSimulationActive = false;
  public urlToWebSite = apiSettings.webBaseUrl;
  constructor(public footerService: FooterService) {}

  ngOnInit() {
    this.footerService
      .getFooter("FR")
      .toPromise()
      .then(data => {
        (this.footer = this.buildFooter(data)), (this.isFormReady = true);
      });
  }

  buildFooter(data: Footer): Footer {
    data.footerLinks = this.buildMenu(data.footerLinks);
    data.socialLinks = this.buildMenu(data.socialLinks);
    return data;
  }
  buildMenu(data: Menu): Menu {
    data.subMenus.forEach(element => {
      if (element.url.indexOf(apiSettings.spaBaseUrl) >= 0) {
        element.url = element.url.replace(apiSettings.spaBaseUrl, "");
        element.isAngularComponent = true;
      } else 
        {
          if (element.url.indexOf("//") >= 0) {
          } else {
            if (element.url.indexOf(this.urlToWebSite) < 0) {
              element.url = this.urlToWebSite + element.url;
          }
        }
      }
    });

    return data;
  }
}
