import { UserService } from "app/api/identity/services";
import { BehaviorSubject, from, Observable, Subject, Subscriber } from "rxjs";
import {
  Injectable,
  EventEmitter,
  OnInit,
  Inject,
  OnDestroy,
} from "@angular/core";
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import {
  isIE,
  b2cPolicies,
  protectedResourceMapConfig,
} from "./../../../environments/environment";
import { EnumUserRole, UserDto } from "app/api/identity/models";
import { filter, first, map, take, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import {
  AuthenticationResult,
  AuthenticationScheme,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  Logger,
  LogLevel,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { hasValue } from "app/layouts/naxos-framework/services";
import { User } from "oidc-client";

// Log.logger = console;
// Log.level = Log.DEBUG;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  title = "Azure AD B2C";
  loggedIn = false;
  loggedIn$ = new Subject<boolean>();
  authResult$: Observable<any>;
  previousLoggedIn = false;
  currentUser: UserDto;
  currentUser$: Observable<UserDto>;
  currentUserSubject: Subject<UserDto>;
  isAdmin = false;
  isAdmin$: Observable<boolean>;
  initials = "";
  initials$: Observable<string>;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private userService: UserService,
  ) {
    this.currentUserSubject = new Subject();
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
    });

    this.isAdmin$ = this.currentUser$.pipe(
      map((user) => user.userRole === 0)
    );
    this.isAdmin$.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });

    this.initials$ = this.currentUser$.pipe(
      map((user) => user.firstName.charAt(0) + "" + user.lastName.charAt(0))
    );
    this.initials$.subscribe((initials) => {
      this.initials = initials;
    });

    this.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.setCurrentUser();
      }
    });

    this.setLoggedIn();

    this.msalService.setLogger(
      new Logger({
        logLevel: LogLevel.Error,
        loggerCallback: (logLevel, message, piiEnabled) => {
          console.log("MSAL Logging: ", message);
        },
      })
    );
  }

  setLoggedIn() {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
    this.loggedIn$.next(this.loggedIn);
  }

  async setCurrentUser() {
    await this.userService.GetCurrentUser()
      .toPromise()
      .then(
        (user) => {
          this.currentUserSubject.next(user);
        }
      );
  }

  getAllAccounts() {
    return this.msalService.instance.getAllAccounts();
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authResult$ = this.msalService.loginPopup({
        ...this.msalGuardConfig.authRequest,
        extraQueryParameters: { ui_locales: "fr" },
      } as PopupRequest);

      this.authResult$.subscribe((authResult) => {
        const authres = authResult as AuthenticationResult;

        this.msalService
          .acquireTokenPopup({
            scopes: [].concat.apply(
              [],
              Array.from(protectedResourceMapConfig.values())
            ),
          })
          .subscribe((authresult) => {
            this.setLoggedIn();
          });
      });
    } else {
      this.authResult$ = this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        extraQueryParameters: { ui_locales: "fr" },
      } as RedirectRequest);
    }
  }

  signup() {
    const request = {
      ...this.msalGuardConfig.authRequest,
      extraQueryParameters: {
        option: 'signup',
        ui_locales: "fr",
    },
    } as RedirectRequest;
    this.msalService.loginRedirect(request);
  }

  resetPassword() {
    const request = {
      ...this.msalGuardConfig.authRequest,
      extraQueryParameters: {
        ui_locales: 'fr',
        option: 'forgotpassword'
    },
    } as RedirectRequest;
    this.msalService.loginRedirect(request);
  }

  logout(): Observable<void> {
    return this.msalService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  getInitials() {
    if (this.currentUser) {
      return (
        this.currentUser.firstName.charAt(0) +
        "" +
        this.currentUser.lastName.charAt(0)
      );
    } else {
      return "";
    }
  }
}
