import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-http-error-details',
  templateUrl: './http-error-details.component.html',
  styleUrls: ['./http-error-details.component.scss']
})
export class HttpErrorDetailsComponent implements OnInit {
  error: HttpErrorResponse;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
