import { Component, OnInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { SidebarService } from "../service/sidebar.service";
import { ProfileSimulationService } from "app/layouts/profile/service/profile-simulation.service";
import { AuthService } from "app/security/services";
import { TranslateService } from "@ngx-translate/core";
import { hasValue } from "app/layouts/naxos-framework/services";
import { User } from "oidc-client";
import { Router } from "@angular/router";
import { JsonPipe } from "@angular/common";
import { ProfileSimulation } from "app/layouts/profile/model/profile_simulation";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [
    trigger("slide", [
      state("up", style({ height: 0 })),
      state("down", style({ height: "*" })),
      transition("up <=> down", animate(200))
    ])
  ]
})
export class SidebarComponent implements OnInit {
  public isAdmin = false;
  isMenuLoaded = false;
  public currentProfileSim = new ProfileSimulation();
  constructor(
    public router: Router,
    public sidebarservice: SidebarService,
    public auth: AuthService,
  ) {
    this.isAdmin = this.auth.isAdmin;
    this.auth.isAdmin$.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });
  }

  ngOnInit() {

  }
}
