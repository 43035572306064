import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NaxosProblemDetails } from '../models/naxos-problem-details';
@Component({
  selector: 'app-naxos-error-details',
  templateUrl: './naxos-error-details.component.html',
  styleUrls: ['./naxos-error-details.component.scss']
})
export class NaxosErrorDetailsComponent implements OnInit {
  error: NaxosProblemDetails;

  constructor(
    public bsModalRef: BsModalRef,
    private dialog: MatDialog,
  ) {
    this.error = this.error;
  }

  ngOnInit() {
  }

}
