import { ProfileInfo } from 'app/layouts/profile/model/profileInfo';
import { Component, OnInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { SidebarService } from "../service/sidebar.service";
import { ProfileSimulationService } from "app/layouts/profile/service/profile-simulation.service";
import { AuthService } from "app/security/services";
import { TranslateService } from "@ngx-translate/core";
import { hasValue } from "app/layouts/naxos-framework/services";
import { User } from "oidc-client";
import { Router } from "@angular/router";
import { JsonPipe } from "@angular/common";
import { ProfileSimulation } from "app/layouts/profile/model/profile_simulation";
import { UserDto } from "app/api/identity/models";
import { UserService } from 'app/api/identity/services';
import { ProfileInfoService } from 'app/layouts/profile/service/profileInfo.service';

@Component({
  selector: "app-admin-sidebar",
  templateUrl: "./admin-sidebar.component.html",
  styleUrls: ["../sidebar/sidebar.component.scss"],
  animations: [
    trigger("slide", [
      state("up", style({ height: 0 })),
      state("down", style({ height: "*" })),
      transition("up <=> down", animate(200))
    ])
  ]
})
export class AdminSidebarComponent implements OnInit {
  menus = [];
  simulationMenu = [];
  public modeSimulationActive = false;
  public currentUser: UserDto;
  public currentProfileSimulated: ProfileInfo;
  public currentProfile: ProfileInfo;
  isFormReady = false;

  isMenuLoaded = false;
  isLoggedIn = false;
  public currentProfileSim = new ProfileSimulation();
  constructor(
    public router: Router,
    public sidebarservice: SidebarService,
    private userService: UserService,
    private profileSimulationService: ProfileSimulationService,
    private profileService: ProfileInfoService,
    public auth: AuthService
  ) {
    this.menus = sidebarservice.getAdminMenuList();
    this.simulationMenu = sidebarservice.getSimulationMenuList();
  }

  ngOnInit() {
    if (hasValue(this.auth.currentUser)) {
      this.loadMenuForAuthentificatedUser();
      this.isFormReady = true;
      this.isLoggedIn = true;
    }
  }

  loadMenuForAuthentificatedUser(): any {
    this.userService.GetCurrentUser()
      .toPromise()
      .then(data => {
        this.currentUser = data;
        console.log(this.currentUser);
        this.profileService.GetByIdentityUserId(this.currentUser.id)
          .toPromise()
          .then(profileData => {
            this.currentProfile = profileData;
            console.log(this.currentProfile);
          })
      })
    if (this.auth.isAdmin) {
      this.initializeAdminMenu();
    } else {
      this.menus = this.menus.filter(listTitle => listTitle.admin === false);
    }
  }
  initializeAdminMenu(): void {
    this.profileSimulationService
      .GetCurrentSimulationByCurrentAdmin()
      .toPromise()
      .then(data => {
        if (data !== null && data !== undefined) {
          this.modeSimulationActive = true;
          this.currentProfileSim = data;
          this.menus = this.menus;
          this.profileService
            .GetByProfileId(this.currentProfileSim.simulatedProfileId)
            .toPromise()
            .then((simulatedProfileData) => {
              this.currentProfileSimulated = simulatedProfileData;
              console.log(this.currentProfileSimulated);
            },
              (error) => {
                console.log(error);
              }
            );

        } else {
          // this.menus = this.menus.filter(
          //   listTitle => listTitle.admin === true || listTitle.public === true
          // );
        }
      });


  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  toggle(currentMenu) {
    if (currentMenu.type === "dropdown") {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }

      });

      this.simulationMenu.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }

      });

    }
  }

  getState(currentMenu) {
    if (currentMenu.active) {
      return "down";
    } else {
      return "up";
    }
  }

  hasBackgroundImage() {
    return this.sidebarservice.hasBackgroundImage;
  }

  stopSimulation() {
    this.profileSimulationService
      .StopSimulation()
      .toPromise()
      .then(() => {
        this.router.navigateByUrl("/dashboard").then(() => { });
      });
  }
}
