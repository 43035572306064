<nav class="navbar navbar-expand-lg" style="position: fixed;" color-on-scroll="100">
  <div class="container">
    <div class="navbar navbar-expand-lg
      navbar-light bg-light fixed-top py-lg-2">
      <div class="navbar-translate">

        <a class="navbar-brand" href="{{ urlToWebSite }}">
          <img src="assets/img/logo-naxosit.png" height="30" alt="Logo" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="sr-only"></span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto" *ngIf="isMenuLoaded">
          <li class="dropdown nav-item" *ngFor="let menu of menus">
            <a *ngIf="!menu.isAngularComponent" href="{{ menu.url }}" class="nav-link">
              {{ menu.title }}
            </a>
            <a *ngIf="menu.isAngularComponent" [routerLink]="[menu.url]" class="nav-link">
              {{ menu.title }}
            </a>
            <div class="dropdown-menu" *ngIf="menu.subMenus !== null">
              <a href="{{ subMenu.url }}" class="dropdown-item" *ngFor="let subMenu of menu.subMenus">
                {{ subMenu.title }}
              </a>
            </div>
          </li>

          <li class="dropdown nav-item" style="margin-right: 3rem ;" *ngIf="isLoggedIn">
            <a href="#" class="dropdown-toggle nav-link dropdown-item" data-toggle="dropdown">
              {{ (currentUser?.firstName == null ? '' : currentUser.firstName) + ' ' + (currentUser?.lastName == null ?
              ''
              : currentUser.lastName) }}
            </a>
            <div id="log" class="dropdown-menu">
              <a [routerLink]="['/profile/account']" class="dropdown-item">
                {{ "Account" | translate }}</a>
              <a [routerLink]="['/logout']" class="dropdown-item">
                {{ "Log off" | translate }}</a>
            </div>
          </li>
          <li class="nav-item" *ngIf="!isLoggedIn">
            <a [routerLink]="" class="nav-link" (click)="login()">
              {{ "Log in" | translate }}
            </a>
          </li>
          <!-- END: USER INFORMATION -->
        </ul>
      </div>
    </div>

  </div>
</nav>

<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
  <div class="container-fluid">
    <div class="navbar-wrapper" *ngIf="modeSimulationActive" style="top: 12px">
      <a class="navbar-brand" href="#">{{ getTitle() }}</a>

      <button type="button" class="btn btn-naxos-normal waves-effect" (click)="stopSimulation()">
        Close
      </button>
    </div>

    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <!-- <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#pablo"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="material-icons">notifications</i>
            <span class="notification">5</span>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <a class="dropdown-item" href="#"
              >Mike John responded to your email</a
            >
            <a class="dropdown-item" href="#">You have 5 new tasks</a>
            <a class="dropdown-item" href="#">You're now friend with Andrew</a>
            <a class="dropdown-item" href="#">Another Notification</a>
            <a class="dropdown-item" href="#">Another One</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="profile/profile">
            <i class="material-icons">person</i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li>
      </ul> -->
    </div>
  </div>
</nav>
