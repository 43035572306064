import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ServerError } from "@azure/msal-browser";
import { ErrorService } from "app/shared/errors/error.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private router: Router,
    private error: ErrorService,
    ) {}

  handleError(error) {
    let treatError = true;
    if (error instanceof ServerError) {
      if (error?.message?.includes("AADB2C90091")) {
        treatError = false;
        if (this.router.url.startsWith("/public/login-failed")) {
          this.router.navigateByUrl("/public/home");
        }
      }
    }
    if (treatError)
    {
      console.error(error);
      if(error.message != "Cannot read properties of undefined (reading 'ɵcmp')"){ //until I find what it is
        this.error.error(error);
      }
    }
  }
}
