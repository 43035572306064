import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalizationInterceptor implements HttpInterceptor {
  constructor(
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let languageRequest = req.clone({ setHeaders: { 'Accept-Language': 'fr' } });
    return next.handle(languageRequest);
  }
}
