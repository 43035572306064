import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, Injector, Injectable } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  RouterModule,
  UrlSerializer,
  DefaultUrlSerializer,
  UrlTree
} from "@angular/router";
import { routes } from './app.routing'

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { ApiModule as IdentityApiModule } from "./api/identity/api.module";

import { AppComponent } from "./app.component";


import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from "@angular/common/http";
import { NaxosNotificationService, AuthService } from "./security/services";
import { AuthGuard, AdminGuard } from "./security";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LogoutComponent } from "./components/logout/logout.component";
import { SidebarModule } from "ng-sidebar";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SidebarTogglerComponent } from "./components/sidebar-toggler/sidebar-toggler.component";
@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    return super.parse(url.toLowerCase());
  }
}

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
};

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import { apiSettings, interactionType, isIE } from "environments/environment";
import {
  msalConfig,
  protectedResourceMapConfig,
} from "./../environments/environment";
import { AuthInterceptor } from "./security/services/auth.interceptor";
import { CommonModule } from "@angular/common";
import { CoreModule } from './core/core.module';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = protectedResourceMapConfig;

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: interactionType,
    loginFailedRoute: '/dashboard',
  };
}

@NgModule({
  imports: [
    IdentityApiModule.forRoot({ rootUrl: apiSettings.IdentityApiEndPoint }),

    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ComponentsModule,
    RouterModule.forRoot(
      routes,
      { relativeLinkResolution: 'legacy' },
      //{ enableTracing: true } // <-- debugging purposes only
    ),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    SidebarModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MsalModule,
    CoreModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    SidebarTogglerComponent,
  ],
  providers: [
    NaxosNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    AuthGuard,
    AdminGuard,
    AuthService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}

export let AppInjector: Injector;
