<div class="modal-header">
  <h4 class="modal-title pull-left">Error details</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Error name: {{ error?.name }}
  </p>
  <p>
    Error status: {{ error?.status }}
  </p>
  <p>
    Error status text: {{ error?.statusText }}
  </p>
  <p>
    Error message: {{ error?.message }}
  </p>
  <p>
    More info: {{ error?.error }}
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
</div>
