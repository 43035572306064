import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-multiple-errors',
  templateUrl: './multiple-errors.component.html',
  styleUrls: ['./multiple-errors.component.scss']
})
export class MultipleErrorsComponent implements OnInit {
  seeAllErrors?: boolean = null;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  setSeeAllErrors(seeAllErrors: boolean) {
    this.seeAllErrors = seeAllErrors;
    this.bsModalRef.hide();
  }

}
