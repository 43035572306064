
<h4>StackFrame</h4>
<br>
filePath: {{ stackFrame?.filepath }}
<br>
fileName: {{ stackFrame?.fileName }}
<br>
function: {{ stackFrame?.function }}
<br>
line: {{ stackFrame?.line }}
<br>
preContextLine: {{ stackFrame?.preContextLine }}
<br>
<app-code title="preContextCode" [code]="stackFrame?.preContextCode"></app-code>
<app-code title="contextCode" [code]="stackFrame?.contextCode"></app-code>
<app-code title="postContextCode" [code]="stackFrame?.postContextCode"></app-code>
