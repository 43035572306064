import { AuthService } from 'app/security/services';
import { apiSettings } from "environments/environment";
import { Injectable, OnInit } from "@angular/core";
import { BaseApiService } from "app/layouts/naxos-framework/services";
import { FormGroup, Validators } from "@angular/forms";
import { FieldConfig } from "app/layouts/naxos-framework/from/field.interface";
import { HttpClient } from "@angular/common/http";
import { ProfileInfo } from "../model/profileInfo";
import { Observable, of, Subject } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { UserDto } from "app/api/identity/models";

const apiUrl = apiSettings.ProfileApiEndPoint + "/api/profiles";

@Injectable({
  providedIn: "root"
})
export class ProfileInfoService extends BaseApiService {
  public form: FormGroup;
  unsubcribe: any;
  public fields: FieldConfig[] = [];

  currentProfile: ProfileInfo;
  currentProfile$: Observable<ProfileInfo>;
  currentProfileSubject: Subject<ProfileInfo>;

  constructor(
    public http: HttpClient,
    private auth: AuthService,
    ) {
    super(http, apiUrl);

    if (this.auth.loggedIn) {
      this.currentProfileSubject = new Subject();
      this.currentProfile$ = this.currentProfileSubject.asObservable();
      this.currentProfile$.subscribe((currentProfile) => {
        this.currentProfile = currentProfile;
      });

      // this.auth.currentUser$.subscribe((user) => {
      this.setCurrentProfile();
      // })
    }

  }
  load() {}
  initialize(): any {}

  //#endregion

  //#region FORM

  GetByProfileId(id: string): any {
    return this.http.get<any>(this.apiUrl + "/" + id);
  }

  GetByIdentityUserId(id: string): any {
    return this.http.get<any>(this.apiUrl + "/GetByIdentityUserId?identityUserId=" + id);
  }

  getfields(isAdmin = false) {
    if (isAdmin) {
      return this.fields;
    } else {
      this.fields.forEach(it => {
        if (it.justVisibleforAdmin) {
          it.type = "";
        }
      });
      return this.fields;
    }
  }

  async setCurrentProfile() {
    await this.http.get<any>(this.apiUrl +"/GetCurrentProfile")
      .toPromise()
      .then(
        (profile) => {
          this.currentProfileSubject.next(profile);
        }
      );
  }

  Clone(profileId: string): any {
    return this.http.get(this.apiUrl + "/CloneProfile?id=" + profileId);
  }

  hasAllRequiredToAppy(): Observable<Boolean> {
    return this.http.get<Boolean>(this.apiUrl + "/HasAllRequiredToAppy");
  }

  profileExists(profileId: string): Observable<Boolean> {
    return this.http.get<Boolean>(this.apiUrl + "/Exists" + profileId);
  }

  searchByLastName(term: string): Observable<ProfileInfo[]> {
    term = encodeURIComponent(term);
    return this.http
      .get<ProfileInfo[]>(
        this.apiUrl + "/GetProfileByLastName?lastName=" + term
      )
      .pipe(
        tap(_ => this.log(`found Profile matching "${term}"`)),
        catchError(this.handleError<ProfileInfo[]>("searchByLastName", []))
      );
  }

  ngDistroy() {
    this.unsubcribe();
  }
  setForm(model: ProfileInfo): any {
      this.fields = [
        {
          type: "input",
          inputType: "text",
          name: "email",
          label: "Email",
          value: model.email,
          justVisibleforAdmin: false,
          validations: [
            {
              name: "required",
              validator: Validators.required,
              message: "Email is required"
            },
            {
              name: "pattern",
              validator: Validators.pattern(
                "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"
              ),
              message: "Invalid email"
            }
          ]
        },
        {
          type: "input",
          inputType: "text",
          name: "firstName",
          label: "First Name",
          value: model.firstName,
          justVisibleforAdmin: false,
          validations: [
            {
              name: "required",
              validator: Validators.required,
              message: "First name is required"
            }
          ]
        },
        {
          type: "input",
          inputType: "text",
          name: "lastName",
          label: "Last Name",
          value: model.lastName,
          justVisibleforAdmin: false,
          validations: [
            {
              name: "required",
              validator: Validators.required,
              message: "Last Name is required"
            }
          ]
        },
        {
          type: "date",
          name: "birthDate",
          label: "Birth Date",
          value: model.birthDate,
          justVisibleforAdmin: false
        },

        {
          type: "input",
          inputType: "text",
          name: "title",
          label: "Title",
          value: model.title,
          justVisibleforAdmin: false
        },
        {
          type: "textarea",
          inputType: "text",
          name: "summary",
          label: "Summary",
          value: model.summary,
          justVisibleforAdmin: false
        },
        {
          type: "input",
          inputType: "text",
          name: "industry",
          label: "Industry",
          value: model.industry,
          justVisibleforAdmin: false
        },

        {
          type: "date",
          name: "availableFrom",
          label: "Available from",
          value: model.availableFrom,
          justVisibleforAdmin: false
        },

        // START  HIDDEN

        {
          type: "",
          inputType: "text",
          name: "topSkills",
          label: "Top skills",
          value: model.topSkills,
          justVisibleforAdmin: true
        },

        {
          type: "",
          name: "ProfileId",
          value: model.sourceProfileId,
          justVisibleforAdmin: true
        },
        {
          type: "",
          label: "Public",
          name: "isPublic",
          value: model.isPublic,
          justVisibleforAdmin: true
        },
        // END  HIDDEN
        {
          type: "checkbox",
          label: "Deleted",
          name: "isDeleted",
          value: model.isDeleted,
          justVisibleforAdmin: true
        },
        {
          type: "checkbox",
          label: "Consultant",
          name: "isConsultant",
          value: model.isConsultant,
          justVisibleforAdmin: true
        },
        {
          type: "checkbox",
          label: "User",
          name: "isUser",
          value: model.isUser,
          justVisibleforAdmin: true
        },
        {
          type: model.isPublic ? "checkbox" : "",
          label: "Online",
          name: "isOnline",
          value: model.isOnline,
          justVisibleforAdmin: true
        },
        {
          type: "button",
          label: "Save",
          justVisibleforAdmin: false
        }
      ];
  }
  //#endregion

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a nodeService message with the MessageService */
  private log(message: string) {}
}
