import { Subscription } from 'rxjs';
import { AppInjector } from './../../app.module';
import { Inject, Injectable, Injector } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NaxosError } from "../errors/models/naxos-error";
import { ErrorToastComponent } from "./error-toast/error-toast.component";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor() {}

  private get toastr() {
    return AppInjector.get(ToastrService);
  }

  error(error: NaxosError) {
    const activeToast = this.toastr.error(error.message, error.title, {
      toastComponent: ErrorToastComponent,
      closeButton: true,
      disableTimeOut: true,
      tapToDismiss: false,
    });
    activeToast.toastRef.componentInstance.errors.push(error.error);

    let subscription : Subscription = activeToast.toastRef.componentInstance.subscription;
    if (subscription) {
      subscription.unsubscribe();
    }
    subscription = activeToast.onTap.subscribe({next: () => {
      activeToast.toastRef.componentInstance.openDetails();
    }});
    activeToast.toastRef.componentInstance.subscription = subscription;
  }

  success(message, title) {
    this.toastr.success(message, title);
  }

  warning(message, title) {
    this.toastr.warning(message, title);
  }

  info(message, title) {
    this.toastr.info(message, title);
  }

  show(message, title) {
    this.toastr.show(message, title);
  }
}
