import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { ErrorInterceptor } from "./errors/error.interceptor";
import { GlobalErrorHandler } from "./errors/global-error-handler";
import { HttpLoadingInterceptor } from "./errors/http-loading.interceptor";
import { LocalizationInterceptor } from "./errors/localization.interceptor";

@NgModule({
  declarations: [],
  imports: [
    SharedModule
  ],
  providers: [
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      // interceptor to show loading spinner
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
    {
      // interceptor to catch errors
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      // interceptor to add localization to requests
      provide: HTTP_INTERCEPTORS,
      useClass: LocalizationInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
