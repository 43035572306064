import { Injectable } from "@angular/core";

import { FormBuilder, Form, FormGroup } from "@angular/forms";
import { NaxosNotificationComponent } from "app/layouts/naxos-framework/from/naxos-notification/naxos-notification.component";
import { TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: "root"
})
export class NaxosNotificationService {
  private notificationform: NaxosNotificationComponent;
  // const type = ['','info','success','warning','danger'];
  private _form: FormGroup;
  public content = this.translate.instant("Awesome! Well executed");
  public title = this.translate.instant("Well done");
  public timeOut: Number = 1200;
  constructor(private translate: TranslateService) {
    this.title = this.translate.instant(this.title);
    this.content = this.translate.instant(this.content);
    this.notificationform = new NaxosNotificationComponent();
  }
  public Success(
    title: string = this.title,
    content = this.translate.instant(this.content)
  ) {
    content = this.translate.instant("Awesome! Well executed");
    this.notificationform.run(content, "success");
  }
  public Error(
    title: string = this.translate.instant("Error"),
    content: string = this.translate.instant("Whoops, something went wrong.")
  ) {
    this.notificationform.run(content, "danger");
  }
  public Warn(title: string, content: string) {
    this.notificationform.run(content, "warning");
  }
  public Info(content: string) {
    this.notificationform.run(content, "info");
  }
}
