import { AuthService } from 'app/security/services';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'environments/environment.local';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.login();
  }

  async login() {
    if (!this.auth.loggedIn) {
      const msalInstance = new PublicClientApplication(msalConfig);
      await msalInstance.handleRedirectPromise();
      this.auth.login();
    } else {
      if (this.auth.isAdmin) {
        this.router.navigateByUrl("profile/adminprofile");
      } else {
        this.router.navigateByUrl("public/missions");
      }
    }
  }
}
