import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stack-frame',
  templateUrl: './stack-frame.component.html',
  styleUrls: ['./stack-frame.component.scss']
})
export class StackFrameComponent implements OnInit {
  @Input() stackFrame;

  constructor() { }

  ngOnInit() {
  }

}
