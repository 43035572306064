import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import { apiProfileConfig, apiSettings } from "environments/environment";
import {
  HttpHeaders,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { Inject, Injectable } from "@angular/core";
import { NaxosNotificationService } from "./NaxosNotification.service";
import { TranslateService } from "@ngx-translate/core";
import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import {
  apiIdentityConfig,
  apiMissionConfig,
} from "environments/environment.local";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
httpOptions.headers.append(
  "Access-Control-Allow-Methods",
  "GET, POST, OPTIONS, PUT, DELETE"
);

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes(apiSettings.MissionApiEndPoint) ||
      request.url.includes(apiSettings.ProfileApiEndPoint)
    ) {
      return from(this.handleMissionAndProfileApiRequest(request, next));
    } else {
      return next.handle(request);
    }
  }

  async handleMissionAndProfileApiRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    try {
      const account = this.msalService.instance.getAllAccounts()[0];
      if (account) {
        const token = await this.getToken(account);
        request = request.clone({
          setHeaders: {
            Authorization: token,
          },
        });
      }
    } finally {
      return next.handle(request).toPromise();
    }
  }

  async getToken(account: AccountInfo) {
    const accessTokenRequest = {
      scopes: [
        ...apiProfileConfig.b2cScopes,
        ...apiMissionConfig.b2cScopes,
      ],
      account: account,
    } as SilentRequest;
    const accessTokenResponse = await this.msalService
      .acquireTokenSilent(accessTokenRequest)
      .toPromise();
    return `Bearer ${accessTokenResponse.accessToken}`;
  }
}
