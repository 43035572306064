<div class="modal-header">
  <h4 class="modal-title pull-left">Error details</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    WebApi: {{ error?.webApi }}
  </p>
  <p>
    Type: {{ error?.type }}
  </p>
  <p>
    Status: {{ error?.status }}
  </p>
  <p>
    Title: {{ error?.title }}
  </p>
  <p>
    Detail: {{ error?.detail }}
  </p>
  <p>
    Instance: {{ error?.instance }}
  </p>
  <p>
    TraceId: {{ error?.traceId ?? error?.extensions?.traceId }}
  </p>
  <h1>ExceptionDetails:</h1>
  <app-exception-detail [exceptionDetail]="exceptionDetail" *ngFor="let exceptionDetail of error?.extensions?.exceptionDetails"></app-exception-detail>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
</div>
