import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoadingDialogService {

  constructor() {

  }

  openDialog() {

  }

  hideDialog() {

  }

}
