<ng-container *ngIf="isFormReady">
  <nav id="sidebar" class="sidebar" [ngClass]="{ 'sidebar-bg': hasBackgroundImage() }">
    <div class="sidebar-content">
      <perfect-scrollbar>
        <div class="sidebar-menu">
          <ul>
            <li *ngFor="let menu of menus" [ngClass]="{
              active: menu.active,
              'sidebar-dropdown': menu.type === 'dropdown',
              'header-menu': menu.type === 'header'
            }">
              <span *ngIf="menu.type === 'header'">{{
                menu.title | translate
                }}</span>
              <a *ngIf="menu.type !== 'header' && menu.test == 'Utilisateur connecté'" (click)="toggle(menu)">
                <i class="material-icons"> {{ menu.icon }}</i>
                <ng-container *ngIf="currentUser?.firsName != null || currentUser?.lastName != null">
                  <span>{{ currentUser?.firstName + " " + currentUser?.lastName }}</span>
                </ng-container>
                <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{ menu.badge.text
                  }}</span>
              </a>
              <a *ngIf="menu.type !== 'header' && menu.test != 'Utilisateur connecté'" (click)="toggle(menu)">
                <i class="material-icons"> {{ menu.icon }}</i>
                <span>{{ menu.title | translate }}</span>
                <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{ menu.badge.text
                  }}</span>
              </a>
              <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">


                <ul>
                  <li *ngFor="let submenu of menu.submenus; let i = index" routerLinkActive #subrla="routerLinkActive"
                    [ngClass]="{
                    active: subrla.isActive,
                    'sidebar-subdropdown': menu.type === 'dropdown',
                    'header-menu': menu.type === 'header'
                  }">
                    <ng-container *ngIf="currentProfile?.isConsultant">
                      <a [routerLink]="[submenu.url]">
                        <i *ngIf="submenu.icon !== undefined" class="material-icons">{{ submenu.icon }}</i>
                        <i *ngIf="submenu.icon === undefined" class="material-icons"
                          style="background: transparent"></i>
                        {{ submenu.title | translate }}
                        <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{
                          submenu.badge.text }}</span>
                      </a>
                    </ng-container>

                    <ng-container *ngIf="!currentProfile?.isConsultant && menu.test == 'Utilisateur connecté'">
                      <ng-container *ngIf="i<1">
                        <a [routerLink]="[submenu.url]">
                          <i *ngIf="submenu.icon !== undefined" class="material-icons">{{ submenu.icon }}</i>
                          <i *ngIf="submenu.icon === undefined" class="material-icons"
                            style="background: transparent"></i>
                          {{ submenu.title | translate}}
                          <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{
                            submenu.badge.text }}</span>
                        </a>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!currentProfile?.isConsultant && menu.test != 'Utilisateur connecté'">

                      <a [routerLink]="[submenu.url]">
                        <i *ngIf="submenu.icon !== undefined" class="material-icons">{{ submenu.icon }}</i>
                        <i *ngIf="submenu.icon === undefined" class="material-icons"
                          style="background: transparent"></i>
                        {{ submenu.title | translate}}
                        <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{
                          submenu.badge.text }}</span>
                      </a>
                    </ng-container>
                  </li>
                </ul>
              </div>
            </li>
            <ng-container *ngIf="modeSimulationActive">
              <li *ngFor="let menu of simulationMenu" [ngClass]="{
              active: menu.active,
              'sidebar-dropdown': menu.type === 'dropdown',
              'header-menu': menu.type === 'header'
            }">
                <span *ngIf="menu.type === 'header'">{{
                  menu.title | translate
                  }}</span>
                <a *ngIf="menu.type !== 'header'" (click)="toggle(menu)">
                  <i class="material-icons"> {{ menu.icon }}</i>
                  <ng-container
                    *ngIf="currentProfileSimulated?.firsName != null || currentProfileSimulated?.lastName != null">
                    <span>{{ currentProfileSimulated?.firstName + " " + currentProfileSimulated?.lastName }}</span>
                  </ng-container>
                  <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{ menu.badge.text
                    }}</span>
                </a>
                <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">
                  <ul>
                    <li *ngFor="let submenu of menu.submenus; let i = index" routerLinkActive #subrla="routerLinkActive"
                      [ngClass]="{
                    active: subrla.isActive,
                    'sidebar-subdropdown': menu.type === 'dropdown',
                    'header-menu': menu.type === 'header'
                  }">
                      <ng-container *ngIf="currentProfileSimulated?.isConsultant">

                        <a [routerLink]="[submenu.url, currentProfileSim.simulatedProfileId]">
                          <i *ngIf="submenu.icon !== undefined" class="material-icons">{{ submenu.icon }}</i>
                          <i *ngIf="submenu.icon === undefined" class="material-icons"
                            style="background: transparent"></i>
                          {{ submenu.title | translate }}
                          <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{
                            submenu.badge.text }}</span>
                        </a>
                      </ng-container>

                      <ng-container *ngIf="!currentProfileSimulated?.isConsultant">
                        <ng-container *ngIf="i<1">
                          <a [routerLink]="[submenu.url, currentProfileSim.simulatedProfileId]">
                            <i *ngIf="submenu.icon !== undefined" class="material-icons">{{ submenu.icon }}</i>
                            <i *ngIf="submenu.icon === undefined" class="material-icons"
                              style="background: transparent"></i>
                            {{ submenu.title | translate }}
                            <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{
                              submenu.badge.text }}</span>
                          </a>
                        </ng-container>
                      </ng-container>

                    </li>
                  </ul>
                </div>
              </li>
            </ng-container>
          </ul>


        </div>
      </perfect-scrollbar>
    </div>

    <div *ngIf="modeSimulationActive" class="sidebar-footer">
      <div>
        <a style="cursor: pointer;">
          <i class="fa fa-power-off i-naxos-padding" (click)="stopSimulation()"></i>
        </a>
        <span class="span-naxos-padding-right" style="color: #ffffff">
          <ng-container *ngIf="currentProfileSimulated?.firsName != null || currentProfileSimulated?.lastName != null">
            <strong> {{ currentProfileSimulated?.firstName + " " + currentProfileSimulated?.lastName }}</strong>
          </ng-container>
        </span>
      </div>
    </div>
  </nav>
</ng-container>