<div class="page-wrapper" [ngClass]="{ toggled: getSideBarState() }">
  <app-navbar></app-navbar>
  <main class="page-content">
    <div *ngIf="isAuthentificated">
      <app-sidebar></app-sidebar>
      <app-sidebar-toggler (toggled)="toggleSidebar()"></app-sidebar-toggler>
    </div>

    <div class="container-fluid" style="padding-left:25px;">
      <div style="min-height: 69vh;">
        <router-outlet>
          <div
            class="overlay"
            (click)="toggleSidebar()"
            [ngClass]="{ show: !getSideBarState() }"
          ></div>
        </router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </main>
  <div class="attribution text-center badge badge-warning"></div>
</div>
