<div class="modal-header">
  <h4 class="modal-title pull-left">Multiple errors</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    <button class="btn btn-dark" (click)="setSeeAllErrors(true)">See all</button>
  </p>
  <p>
    <button class="btn btn-dark" (click)="setSeeAllErrors(false)">See only one</button>
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
</div>
