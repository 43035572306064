<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>
<footer class="footer footer-black">
  <div class="container" *ngIf="isFormReady">
    <div class="row">
      <div class="col-md-3">
        <a class="footer-brand" href="{{ urlToWebSite }}">{{ footer.brand }}</a>
      </div>
      <div class="col-md-6">
        <ul class="pull-center">
          <li *ngFor="let link of footer.footerLinks.subMenus">
            <a href="{{ link.url }}">
              <i class="fa {{ link.icon }}"></i>
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>

      <div class="col-md-3" style="text-align:right">
        <ul class="social-buttons">
          <li *ngFor="let social of footer.socialLinks.subMenus">
            <a href="{{ social.url }}" class="btn btn-just-icon btn-link">
              <i class="fa {{ social.icon }}"></i>
              {{ social.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-4 offset-md-4">
        <div class="pull-center">
          {{ footer.copyright }}
        </div>
      </div>
      <!-- <div class="col-md-4" style="text-align:right">
        1.0.0-alpha.0
      </div> -->
    </div>
  </div>
</footer>
