<h2>ExceptionDetail</h2>
message: {{ exceptionDetail.message }}
<br />
type: {{ exceptionDetail.type }}
<br />
raw: {{ exceptionDetail.raw }}
<br />
<h3>Stack frames</h3>
<div class="stack-frames">
  <app-stack-frame
    [stackFrame]="stackFrame"
    *ngFor="let stackFrame of exceptionDetail.stackFrames"
  ></app-stack-frame>
</div>
