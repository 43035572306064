import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class SidebarService {
  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    // --------------------------------NONE ADMIN-------------------------
    {
      title: "",
      type: "header"
    },
    //
    {
      test: "Utilisateur connecté",
      icon: "group_work",

      active: false,
      url: "#AdminProfile",
      type: "dropdown",
      submenus: [
        {
          url: "/profile/profile",
          title: "Dashboard",
          icon: "perm_identity"
        },
        {
          url: "/profile/applications",
          title: "My Applications",
          icon: "shop_two"
        },
        {
          url: "/profile/skills",
          title: "Skills",
          icon: "receipt"
        },
        {
          url: "/profile/CV",
          title: "CV",
          icon: "next_week"
        },

        {
          url: "/profile/educations",
          title: "Degrees",
          icon: "school"
        },
        {
          url: "/profile/certificates",
          title: "Certifications",
          icon: "card_membership"
        },
        {
          url: "/profile/workexperiences",
          title: "Work Experiences",
          icon: "work"
        },
        {
          url: "/profile/volonteerexperiences",
          title: "Volonteer experiences",
          icon: "nature_people"
        },
        {
          url: "/profile/contacts",
          // title: "Contacts",
          title: this.translate.instant("References"),
          icon: "email"
        },
        {
          url: "/profile/consents",
          title: "Consents",
          icon: "assignment_turned_in"
        }
      ]
    },
    // --------------------------------ADMIN-------------------------
    {
      title: "Administration",
      type: "header"
    },
    // ADMIN PROFILE
    {
      title: "Gestion des profils",
      icon: "group_work",
      active: false,
      url: "#AdminProfile",
      type: "dropdown",
      submenus: [
        {
          url: "/profile/adminProfile",
          title: "Liste des profils",
          icon: "person"
        },
        {
          url: "/profile/AdminUser",
          title: "Liste des utilisateurs",
          icon: "verified_user"
        }
      ]
    },

    // ADMIN REFERENTIAL
    {
      title: "Referential",
      icon: "dashboard",
      active: false,
      url: "#Referential",
      type: "dropdown",
      submenus: [
        {
          url: "/referential/manageskill",
          title: "Manage Skills",
          icon: "receipt"
        },
        {
          url: "/referential/managefunction",
          title: "Manage Functions",
          icon: "receipt"
        },
        {
          url: "/referential/graphskill",
          title: "Skills Graph",
          icon: "receipt"
        },
        {
          url: "/referential/graphfunction",
          title: "Functions Graph",
          icon: "receipt"
        }
      ]
    },
    // ADMIN MISSION
    {
      title: "Mission",
      icon: "line_style",
      active: false,
      type: "dropdown",
      url: "#",
      submenus: [
        {
          title: "Manage Applications",
          url: "/mission/application",
          icon: "shop_two"
        },
        {
          title: "Manage Missions",
          url: "/mission/missions",
          icon: "card_travel"
        },
        {
          url: "../public/missions",
          title: "Liste des missions",
          icon: "card_travel",
          type: "header"
        }
      ]
    },


  ];

  simulationMenu = [
    {
      title: "Simulation",
      type: "header"
    },
    {
      title: "Profile",
      icon: "group_work",

      active: false,
      url: "#AdminProfile",
      type: "dropdown",
      submenus: [
        {
          url: "/profile/profile",
          title: "Dashboard",
          icon: "perm_identity"
        },
        {
          url: "/profile/applications",
          title: "My Applications",
          icon: "shop_two"
        },
        {
          url: "/profile/skills",
          title: "Skills",
          icon: "receipt"
        },
        {
          url: "/profile/CV",
          title: "CV",
          icon: "next_week"
        },

        {
          url: "/profile/educations",
          title: "Degrees",
          icon: "school"
        },
        {
          url: "/profile/certificates",
          title: "Certifications",
          icon: "card_membership"
        },
        {
          url: "/profile/workexperiences",
          title: "Work Experiences",
          icon: "work"
        },
        {
          url: "/profile/volonteerexperiences",
          title: "Volonteer experiences",
          icon: "nature_people"
        },
        {
          url: "/profile/contacts",
          // title: "Contacts",
          title: this.translate.instant("References"),
          icon: "email"
        },
        {
          url: "/profile/consents",
          title: "Consents",
          icon: "assignment_turned_in"
        }
      ]
    }
  ];

  userMenu = [
    {
      url: "/profile/profile",
      title: "Dashboard",
      icon: "perm_identity",
      type: "header"
    },
    {
      url: "/profile/applications",
      title: "My Applications",
      icon: "shop_two",
      type: "header"
    },
    {
      url: "/profile/skills",
      title: "Skills",
      icon: "receipt",
      type: "header"
    },
    {
      url: "/profile/CV",
      title: "CV",
      icon: "next_week",
      type: "header"
    },

    {
      url: "/profile/educations",
      title: "Degrees",
      icon: "school",
      type: "header"
    },
    {
      url: "/profile/certificates",
      title: "Certifications",
      icon: "card_membership",
      type: "header"
    },
    {
      url: "/profile/workexperiences",
      title: "Work Experiences",
      icon: "work",
      type: "header"
    },
    {
      url: "/profile/volonteerexperiences",
      title: "Volonteer experiences",
      icon: "nature_people",
      type: "header"
    },
    {
      url: "/profile/contacts",
      title: this.translate.instant("References"),
      icon: "email",
      type: "header"
    },
    {
      url: "/profile/consents",
      title: "Consents",
      icon: "assignment_turned_in",
      type: "header"
    },
    {
      url: "../public/missions",
      title: "Liste des missions",
      icon: "card_travel",
      type: "header"
    }
  ];
  constructor(private translate: TranslateService) {}

  toggle() {
    this.toggled = !this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getAdminMenuList() {
    return this.menus;
  }

  getSimulationMenuList(){
    return this.simulationMenu;
  }

  getUserMenuList() {
    return this.userMenu;
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
}
