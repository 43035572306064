<nav id="sidebar" class="sidebar" [ngClass]="{ 'sidebar-bg': hasBackgroundImage() }">
  <div class="sidebar-content">
    <perfect-scrollbar>
      <div class="sidebar-menu">
        <ul>
          <li *ngFor="let menu of menus; let i = index" [ngClass]="{
              active: menu.active,
              'sidebar-dropdown': menu.type === 'dropdown',
              'header-menu': menu.type === 'header'
            }">
            <ng-container *ngIf="currentProfile?.isConsultant">
              <a [routerLink]="[menu.url]">
                <i *ngIf="menu.icon !== undefined" class="material-icons">{{
                  menu.icon
                  }}</i>
                <i *ngIf="menu.icon === undefined" class="material-icons" style="background: transparent"></i>
                {{ menu.title | translate }}
                <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{ menu.badge.text
                  }}</span>
              </a>
            </ng-container>

            <ng-container *ngIf="!currentProfile?.isConsultant">
              <ng-container *ngIf="i<1">
                <a [routerLink]="[menu.url]">
                  <i *ngIf="menu.icon !== undefined" class="material-icons">{{
                    menu.icon
                    }}</i>
                  <i *ngIf="menu.icon === undefined" class="material-icons" style="background: transparent"></i>
                  {{ menu.title | translate }}
                  <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{ menu.badge.text
                    }}</span>
                </a>
              </ng-container>
            </ng-container>
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
</nav>
