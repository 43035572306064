import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { apiSettings } from "environments/environment";
import { Observable } from "rxjs";
import { Menu } from "../model/menu";

const apiUrl = apiSettings.ContentApiEndPoint;

@Injectable({
  providedIn: "root"
})
export class MenuService implements OnInit {
  ngOnInit(): void {}

  constructor(public http: HttpClient) {}

  getMenu(language: string, IsUserconnected: boolean): Observable<Menu[]> {
    language = encodeURIComponent(language);
    return this.http.get<Menu[]>(
      apiUrl +
        "GetMenu?locale=" +
        language +
        "&IsUserconnected=" +
        IsUserconnected
    );
  }
}
