import { Component, OnInit, Input } from "@angular/core";
declare const $: any;


@Component({
  selector: "app-naxos-notification",
  styleUrls: ["./naxos-notification.component.css"],
  templateUrl: "./naxos-notification.component.html"
})
export class NaxosNotificationComponent {
  constructor() {}

  run(message: string, type: string) {
    let align = "right";
    let from = "bottom";

    $.notify(
      {
        message: message
      },
      {
        placement: { from, align },
        offset: { x: 20, y: 35 },
        type,
        template: `
        <div
        data-notify="container"
        class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon"
        role="alert"
      >
        <button
          mat-button
          type="button"
          aria-hidden="true"
          class="close mat-button"
          data-notify="dismiss"
        >
          <i class="material-icons">close</i>
        </button>
        <i class="material-icons" data-notify="icon">notifications</i>
        <span data-notify="title">{1}</span>
        <span data-notify="message">{2}</span>
        <a href="{3}" target="{4}" data-notify="url"></a>
      </div>`
      }
    );
  }
}
