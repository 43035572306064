import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ErrorDetailsComponent } from 'app/shared/errors/error-details/error-details.component';
import { HttpErrorDetailsComponent } from 'app/shared/errors/http-error-details/http-error-details.component';
import { NaxosProblemDetails } from 'app/shared/errors/models/naxos-problem-details';
import { NaxosErrorDetailsComponent } from 'app/shared/errors/naxos-error-details/naxos-error-details.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { MultipleErrorsComponent } from '../multiple-errors/multiple-errors.component';

@Component({
  selector: 'app-error-toast',
  templateUrl: './error-toast.component.html',
  styleUrls: ['./error-toast.component.scss']
})
export class ErrorToastComponent implements OnInit {
  errors: any[];
  subscription: Subscription;

  constructor(private modalService: BsModalService) {
    this.errors = [];
  }

  ngOnInit(): void {
  }

  openDetails() {
    if (this.errors.length > 1) {
      let bsModalRef = this.modalService.show(MultipleErrorsComponent);
      bsModalRef.onHide.subscribe(() => {
        if (bsModalRef.content.seeAllErrors !== null) {
          if (bsModalRef.content.seeAllErrors) {
            this.seeAllErrors();
          }
          else {
            this.seeOneError();
          }
        }
      })
    } else {
      this.seeOneError();
    }
  }

  seeAllErrors() {
    this.errors.forEach((value) => {
      this.displayError(value);
    });
  }

  seeOneError() {
    this.displayError(this.errors[0]);
  }

  displayError(error) {
    if (error instanceof Error) {
      let bsModalRef = this.modalService.show(ErrorDetailsComponent, { initialState: { error: error }});
    } else if (error instanceof HttpErrorResponse) {
      let bsModalRef = this.modalService.show(HttpErrorDetailsComponent, { initialState: { error: error }});
    } else if (this.isNaxosProblemDetails(error)) {
      let bsModalRef = this.modalService.show(NaxosErrorDetailsComponent, { initialState: { error: error }});
    }
  }

  isNaxosProblemDetails(npd): npd is NaxosProblemDetails {
    return 'webApi' in npd;
  }

}
