import { Injectable, OnInit } from "@angular/core";
import { apiSettings } from "environments/environment";
import { BaseApiService } from "app/layouts/naxos-framework/services";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "app/layouts/naxos-framework/from/field.interface";

import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ProfileSimulation } from "../model/profile_simulation";
const apiUrl = apiSettings.ProfileApiEndPoint + "/api/profilesimulations";

@Injectable({
  providedIn: "root"
})
export class ProfileSimulationService extends BaseApiService implements OnInit {
  public form: FormGroup;
  unsubcribe: any;
  public fields: FieldConfig[] = [];

  ngOnInit(): void {}

  constructor(public http: HttpClient) {
    super(http, apiUrl);
  }

  StopSimulation(): Observable<ProfileSimulation> {
    return this.http.delete<ProfileSimulation>(this.apiUrl + "/StopSimulation");
  }
  GetCurrentSimulationByCurrentAdmin(): Observable<ProfileSimulation> {
    return this.http.get<ProfileSimulation>(
      this.apiUrl + "/GetCurrentSimulationByCurrentAdmin"
    );
  }

  load() {}
  initialize(): any {}

  setGraphNode(id: string): any {}
}
