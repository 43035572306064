import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { apiSettings } from "environments/environment";
import { Observable } from "rxjs";
import { Menu } from "../model/menu";
import { Footer } from "../model/footer";

const apiUrl = apiSettings.ContentApiEndPoint;


@Injectable({
  providedIn: 'root'
})
export class FooterService {
  // http://localhost:5003/api/Items/GetFooter?applicationCode=naxos-web&itemCode=footer&locale=FR

  constructor(public http: HttpClient) {}

  getFooter(language: string): Observable<Footer> {
    language = encodeURIComponent(language);
    return this.http.get<Footer>(apiUrl + "GetFooter?locale=" + language);
  }
}
