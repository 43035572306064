import { Component, OnInit, ElementRef } from "@angular/core";

import { Location } from "@angular/common";
import { Router } from "@angular/router";

import { AuthService } from "app/security/services";
import { ProfileSimulationService } from "app/layouts/profile/service/profile-simulation.service";
import { ProfileSimulation } from "app/layouts/profile/model/profile_simulation";
import { MenuService } from "../service/menu.service";
import { Menu } from "../model/menu";
import { hasValue } from "app/layouts/naxos-framework/services";
import { apiSettings, environment } from "environments/environment";
import { UserDto } from "app/api/identity/models";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  public currentUser: UserDto;
  public menus: Menu[];
  isMenuLoaded = false;
  public isAdmin = false;
  public isLoggedIn = false;
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private redirectTo = "#";
  private title: String = "";
  public modeSimulationActive = false;
  private currentProfileSim = new ProfileSimulation();
  // Temporary solution by waiting for merging both websites
  public urlToWebSite = apiSettings.webBaseUrl;
  public urlToSpaSite = apiSettings.spaBaseUrl;
  public loaded = false;
  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    public auth: AuthService,
    public menuService: MenuService,
    private profileSimulationService: ProfileSimulationService
  ) {
    this.isLoggedIn = this.auth.loggedIn;
    this.auth.loggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
    this.currentUser = this.auth.currentUser;
    this.auth.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
    });

    this.location = location;
    this.sidebarVisible = false;
    if (environment.local) {
      this.urlToSpaSite = "";
    }
  }

  initializeTitle(): any {
    this.profileSimulationService
      .GetCurrentSimulationByCurrentAdmin()
      .toPromise()
      .then(data => {
        this.currentProfileSim = data;
        if (data !== null && data !== undefined) {
          this.modeSimulationActive = true;
          this.redirectTo = "profile/adminDashboard";
          this.title = this.currentProfileSim.simulatedProfileName;
        }
      });
  }

  ngOnInit() {
    this.currentUser = this.auth.currentUser;
    this.loaded = true;

    this.menuService
      .getMenu("FR", hasValue(this.currentUser))
      .toPromise()
      .then(data => {
        (this.menus = this.buildMenu(data)), (this.isMenuLoaded = true);
      });

    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.router.events.subscribe(event => {
      this.sidebarClose();
      var $layer: any = document.getElementsByClassName("close-layer")[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
  }
  buildMenu(data: Menu[]): Menu[] {
    data.forEach(element => {
      if (element.url.indexOf("//") >= 0) {
      } else {
        if (element.url.indexOf(apiSettings.spaBaseUrl) >= 0 || element.applicationEnum === 2) {
          element.url = element.url.replace(apiSettings.spaBaseUrl, "");
          element.isAngularComponent = true;
        } else {
          if (element.url.indexOf(this.urlToWebSite) < 0) {
            element.url = this.urlToWebSite + element.url;
          }
        }
      }
    });

    return data;
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function() {
      toggleButton.classList.add("toggled");
    }, 500);

    body.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    body.classList.remove("nav-open");
  }
  sidebarToggle() {
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    return this.title;
  }
  stopSimulation() {
    this.profileSimulationService
      .StopSimulation()
      .toPromise()
      .then(() => {
        this.router.navigateByUrl("/profile/adminProfile").then(() => {});
      });
  }
  login() {
    this.auth.login();
  }

  signup() {
    this.auth.signup();
  }
}
