import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

import { Router } from "@angular/router";

import { AuthService } from "app/security/services";
import { SidebarService } from "app/components/service/sidebar.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"]
})
export class AdminLayoutComponent implements OnInit {
  public opened = false;
  currentURL = this.router.url;

  public isAuthentificated = false;
  public currentYear;
  constructor(
    public location: Location,
    private router: Router,
    private authService: AuthService,
    private sidebarservice: SidebarService
  ) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.isAuthentificated = this.authService.loggedIn;
    this.opened = this.isAuthentificated;

    window.onresize = () => {
      this.opened = false;
    };
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }
  toggleBackgroundImage() {
    this.sidebarservice.hasBackgroundImage = !this.sidebarservice
      .hasBackgroundImage;
  }
  getSideBarState() {
    if (!this.isAuthentificated) {
      this.hideSidebar();
      // return this.isAuthentificated;
    }
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }
}
