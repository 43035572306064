import { ProfileInfo } from 'app/layouts/profile/model/profileInfo';
import { Component, OnInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { SidebarService } from "../service/sidebar.service";
import { ProfileSimulationService } from "app/layouts/profile/service/profile-simulation.service";
import { AuthService } from "app/security/services";
import { TranslateService } from "@ngx-translate/core";
import { hasValue } from "app/layouts/naxos-framework/services";
import { User } from "oidc-client";
import { Router } from "@angular/router";
import { JsonPipe } from "@angular/common";
import { ProfileSimulation } from "app/layouts/profile/model/profile_simulation";
import { UserDto } from "app/api/identity/models";
import { ProfileInfoService } from 'app/layouts/profile/service/profileInfo.service';
import { UserService } from 'app/api/identity/services';

@Component({
  selector: "app-user-sidebar",
  templateUrl: "./user-sidebar.component.html",
  styleUrls: ["../sidebar/sidebar.component.scss"],
  animations: [
    trigger("slide", [
      state("up", style({ height: 0 })),
      state("down", style({ height: "*" })),
      transition("up <=> down", animate(200))
    ])
  ]
})
export class UserSidebarComponent implements OnInit {
  menus = [];
  public modeSimulationActive = false;
  public currentUser: UserDto;
  public currentProfile: ProfileInfo;

  isMenuLoaded = false;
  isLoggedIn = false;
  public currentProfileSim = new ProfileSimulation();
  constructor(
    public router: Router,
    public sidebarservice: SidebarService,
    public auth: AuthService,
    private userService: UserService,
    private profileService: ProfileInfoService
  ) {
    this.menus = sidebarservice.getUserMenuList();
  }

  ngOnInit() {
    this.userService.GetCurrentUser().toPromise()
    .then(data => {
      this.currentUser = data;
      console.log(this.currentUser);
      this.profileService.GetByIdentityUserId(this.currentUser.id)
      .toPromise()
      .then(profileData => {
        this.currentProfile = profileData;
        console.log(this.currentProfile);
      })
    })
    
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  toggle(currentMenu) {
    if (currentMenu.type === "dropdown") {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }
      });
    }
  }

  getState(currentMenu) {
    if (currentMenu.active) {
      return "down";
    } else {
      return "up";
    }
  }

  hasBackgroundImage() {
    return this.sidebarservice.hasBackgroundImage;
  }
}
