import { Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private translateService: TranslateService,
  ) {
            // Add available languages
            this.translateService.addLangs(["en", "fr", "de", "nl"]);
            // this language will be used as a fallback when a translation isn't found in the current language
            this.translateService.setDefaultLang('en');
            // the lang to use, if the lang isn't available, it will use the current loader to get them
            const browserLang = this.translateService.getBrowserLang();
            //this.translateService.use(browserLang.match(/en|fr|de|nl/) ? browserLang : "fr");
            this.translateService.use("fr");
  }

  ngOnInit() {

  }
}
